import React from "react";
import video from '../video3.mp4'; // Ensure the video path is correct

function About() {
  return (
    <header className="about-header">
      <video autoPlay loop muted className="background-video">
        <source src={video} type="video/mp4" />
      </video>
      <div className="overlay">
        <div className="container my-5">
          <h2>About Us</h2>
          <p>
            Incodesera is a cutting-edge software development company specializing in creating tailored digital solutions that optimize operational efficiency across industries. Our portfolio includes innovative HR management systems, dealer management software, and paystub management microservices, all designed to solve complex business challenges with precision and agility. From enhancing HR efficiency with automated paystub processing to reducing inventory losses through real-time tracking, Incodesera provides scalable, reliable, and secure software solutions that drive measurable impact. We empower businesses to achieve new levels of productivity and client satisfaction through technology that simplifies, tracks, and transforms core operations.
          </p>
        </div>
      </div>
    </header>
  );
}

export default About;
