import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Ensure Font Awesome is imported

function Footer() {
  return (
    <footer className="footer mt-auto py-4" style={{ backgroundColor: "#f72c00", color: "#fff" }}>
      <div className="container">
        <div className="row text-justify">
          {/* Company Info */}
          <div className="col-md-4 mb-3">
            <h5>Incodesera</h5>
            <p>Delivering innovative software solutions for modern businesses.</p>
          </div>

          {/* Address */}
          <div className="col-md-4 mb-3">
            <h5>Address</h5>
            <p>Willopenn Dr, Southampton, Pennsylvania (PA), 18966, United States</p>
          </div>

          {/* Quick Links */}
          <div className="col-md-4 mb-3">
            <h5>Quick Links</h5>
            <p>
              <Link to="/" className="text-white">Home</Link> |
              <Link to="/about" className="text-white"> About</Link> |
              <Link to="/projects" className="text-white"> Projects</Link> |
              <Link to="/contact" className="text-white"> Contact</Link>
            </p>
          </div>
        </div>

        {/* Social Media Links and Copyright */}
        <div className="row mt-3 text-center">
          <div className="col mb-3">
            <div className="social-icons">
              <a href="https://www.facebook.com/incodesera" className="social-icon">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://twitter.com" className="social-icon">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://www.linkedin.com/company/incodesera/" className="social-icon">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="row mt-3">
          <div className="col text-center">
            <p>&copy; {new Date().getFullYear()} Incodesera. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
