import React, { useState } from "react";
import video from '../video2.mp4'; // Ensure the video is imported

const projects = [
    {
    title: "Customize Business Software",
    description: "In today's rapidly evolving business landscape, one-size-fits-all solutions often fall short of addressing the unique challenges faced by organizations. Our Customize Business Software offering empowers businesses to tailor their software solutions according to their specific needs and processes. We specialize in developing adaptable software that seamlessly integrates with existing workflows, enhancing operational efficiency and productivity. From user interface customization to the incorporation of specific functionalities, our solutions are designed to reflect the brand's identity and optimize user experience.Our team works closely with clients to understand their objectives, offering personalized consultations to identify key areas for enhancement. Whether it's creating bespoke modules for reporting, analytics, inventory management, or customer relationship management, our solutions provide the flexibility required to thrive in competitive markets.By leveraging the latest technologies and methodologies, we ensure that the customized software remains scalable and future-proof, allowing businesses to adapt to changing demands and grow without constraints. With a focus on usability, security, and performance, our Customize Business Software solutions not only drive operational success but also foster innovation and agility, positioning your organization for sustainable growth."
    },
  {
    title: "Paystub Management",
    description: "The Paystub Management is an innovative solution designed to streamline and enhance the efficiency of human resources operations. By automating paystub generation and tax calculations, this software significantly reduces the time and effort required to manage payroll processes. With a user-friendly interface, HR teams can easily generate accurate paystubs and access essential reporting features. This system is equipped with robust security protocols to ensure sensitive employee information is protected. Furthermore, the integration of real-time data analytics allows HR departments to gain valuable insights into payroll trends, enabling informed decision-making. Overall, this solution transforms traditional payroll practices, making them more efficient, reliable, and compliant."
  },
  {
    title: "Dealer Management Software",
    description: "Our Dealer Management Software is a comprehensive platform tailored for automotive dealerships, designed to optimize inventory management and enhance customer relations. With real-time inventory tracking, dealerships can monitor stock levels, manage orders, and streamline the sales process. The software features advanced reporting tools that provide insights into sales performance, helping managers make data-driven decisions. Additionally, this solution fosters improved communication between sales teams and customers, enabling timely follow-ups and personalized service. By reducing annual inventory losses and improving client relationship management, the Dealer Management Software empowers dealerships to achieve operational excellence and boost profitability."
  },
  {
    title: "HR Management System",
    description: "The HR Management System is a robust solution tailored for large warehouse companies, focusing on effective workforce management. By integrating human tracking and work distribution, this system enhances operational efficiency and productivity. It provides HR teams with powerful tools for employee onboarding, performance evaluation, and leave management, all within a centralized platform. The software's intuitive dashboard offers real-time insights into workforce dynamics, enabling proactive management of personnel and resources. Additionally, the system promotes transparency and accountability by allowing employees to access their schedules, tasks, and performance metrics. Overall, this HR Management System simplifies complex HR processes, ensuring seamless workforce coordination and enhanced employee satisfaction."
  }
];

function Projects() {
  const [activeProjectIndex, setActiveProjectIndex] = useState(0);

  const handleSwitch = (index) => {
    setActiveProjectIndex(index);
  };

  return (
    <div className="projects-page">
      <header className="home-header">
        <video autoPlay loop muted className="background-video">
          <source src={video} type="video/mp4" />
        </video>
        <div className="overlay">
        
          <div className="container my-5" style={{ position: 'relative', zIndex: 2 }}>
        <h2>{projects[activeProjectIndex].title}</h2>
        <p>{projects[activeProjectIndex].description}</p>
        <div className="btn-group mb-3" role="group">
          {projects.map((project, index) => (
            <button 
              key={index} 
              onClick={() => handleSwitch(index)} 
              className={`btn ${activeProjectIndex === index ? 'btn-transparent' : 'btn-transparent'}`} // Toggle class based on active project
              style={{ color: 'white', border: '1px solid white' }} // Add transparent background color and white text
            >
              {project.title}
            </button>
          ))}
        </div>
      </div>
        </div>
      </header>
  
    </div>
  );
}

export default Projects;
