import React from "react";
import video from '../video1.mp4'; // Ensure the video path is correct

function Contact() {
  return (
    <header className="contact-header">
      <video autoPlay loop muted className="background-video">
        <source src={video} type="video/mp4" />
      </video>
      <div className="overlay">
        <div className="row">
          {/* Contact Info */}
          <div className="col-md-6 contact-info">
            <p>Email: <a href="mailto:info@incodesera.com">info@incodesera.com</a></p>
            <p>Address: Willopenn Dr, Southampton, Pennsylvania (PA), 18966, United States</p>
          </div>

          {/* Map */}
          <div className="col-md-6">
            <div style={{ width: "100%", height: "500px" }}>
              <iframe
                title="Google Map"
                style={{ width: "100%", height: "100%", border: 0 }}
                src="https://www.google.com/maps/embed/v1/place?q=Willopenn+Dr+Southampton,+Pennsylvania+(PA),+18966&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Contact;
