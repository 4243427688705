import React from "react";
import video from '.././video.mp4';

function Home() {
  return (
    <header className="home-header">
      <video autoPlay loop muted className="background-video">
        <source src={video} type="video/mp4" />
      </video>
      <div className="overlay">
        <h1>Welcome to Incodesera</h1>
        <p>Delivering Innovative Software Solutions for Modern Businesses</p>
      </div>
    </header>
  );
}

export default Home;
